// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---content-pages-404-mdx": () => import("./../content/pages/404.mdx" /* webpackChunkName: "component---content-pages-404-mdx" */),
  "component---content-pages-about-js": () => import("./../content/pages/about.js" /* webpackChunkName: "component---content-pages-about-js" */),
  "component---content-pages-contact-js": () => import("./../content/pages/contact.js" /* webpackChunkName: "component---content-pages-contact-js" */),
  "component---content-pages-index-js": () => import("./../content/pages/index.js" /* webpackChunkName: "component---content-pages-index-js" */),
  "component---src-components-gallery-page-template-js": () => import("./../src/components/gallery-page-template.js" /* webpackChunkName: "component---src-components-gallery-page-template-js" */)
}

